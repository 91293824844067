<template>
  <div>
    <template v-if="!editing">
      <eden-overview-card :title="title">
        <template slot="action">
          <el-button :type="'text'" :color="'primary'" :disabled="disableUpdate" @click="editing = true">
            Edit Order
          </el-button>
        </template>
        <template slot="content">
          <el-collapse accordion>
            <eden-collapse-item v-for="(item, index) in items" :key="index" :name="`${index}.${item.variation.name}`"
              :activities="item.activities" :wrap-bar="false">
              <template slot="summary">
                <span class="type">
                  {{ formatToTitleCase(item.variation.name) }}
                </span>
                <span class="status">{{
                  formatServiceActivity(item.last_marked_activity)
                }}</span>
              </template>
              <template slot="breakdown">
                <div>
                  <span class="font-sm text-grey-primary is-block">Category: {{ item.categoryName }} - Type:
                    {{ item.typeName }}</span>
                  <div class="mt-10">
                    <span v-if="item.spName" class="font-sm">
                      {{ item.spName }}
                    </span>
                    <span v-else class="font-sm text-uppercase text-danger">
                      No Sp Assigned
                    </span>
                  </div>
                </div>
                <div v-if="item.addons.length" class="breakdown-section addons">
                  <span class="label">Add-ons</span>
                  <span v-for="(addon, i) in item.addons" :key="i" class="addon">{{ formatToTitleCase(addon) }}</span>
                </div>
              </template>
            </eden-collapse-item>
          </el-collapse>
        </template>
      </eden-overview-card>
      <order-address-information :deleted-status="deleted" :type="'delivery'" :order="orderDeliveryInformation" />
    </template>
    <template v-else class="fadeIn">
      <order-beauty-edit :order-id="orderId" :service="service" :items="items" :beauty-type="beautyType"
        :order-delivery-date="delivery" :customer-id="customerId" :disable-update="disableUpdate"
        @toggle-edit="toggleEdit" @get-order="$emit('get-order')" />
    </template>
  </div>
</template>

<script>
import OrderAddressInformation from "@/components/Orders/Order/Address/OrderAddressInformation";
import OrderBeautyEdit from "./OrderBeautyEdit";
import {
  BEAUTY_ITEMS,
  BEAUTY_ITEM_ADDONS,
  BEAUTY_ACTIVITIES,
} from "./beauty.config";

import * as actions from "@/store/action-types";

export default {
  name: "OrderBeauty",
  props: {
    order: {
      type: Object,
      default() {
        return [];
      },
    },
    disableUpdate: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    OrderAddressInformation,
    OrderBeautyEdit,
  },
  data() {
    return {
      beautyItems: BEAUTY_ITEMS(),
      beautyItemAddons: BEAUTY_ITEM_ADDONS(),
      // beautyActivities: BEAUTY_ACTIVITIES(this.extraDetails),
      beautyType: {},
      editing: false,
      items: [],
    };
  },
  computed: {
    deleted() {
      return this.order.customer_email === null ? true : false
    },
    title() {
      return `Beauty Services (${this.items.length})`;
    },
    orderId() {
      return this.order.order_id;
    },
    service() {
      return this.order.service.toLowerCase();
    },
    delivery() {
      return this.order.deliver_to_customer_date;
    },
    isOneTimeOrder() {
      return this.order.is_one_time_order;
    },
    customerId() {
      return this.order.customer_id;
    },
    beautyServicePartners() {
      return this.$store.getters.service_partners_list.beauty;
    },
    orderDeliveryInformation() {
      return {
        order_id: this.order.order_id,
        delivery_date: this.order.deliver_to_customer_date,
        location_area: this.order.location_area,
        address: this.order.home_address,
        phone_number: this.order.phone_number,
      };
    },
  },
  created() {
    this.$store.dispatch(actions.GET_SERVICE_TYPES, "beauty").then(() => {
      this.setBeautyTypeCategories();
    });
  },
  methods: {
    setBeautyTypeCategories() {
      const beautyTypes = this.$store.getters.service_types.beauty;
      const beautyType = beautyTypes.filter((type) => {
        return type.slug === this.order.beauty_type;
      });
      const { id, service_type, beauty_categories } = beautyType[0];
      this.beautyType = {
        id,
        name: service_type,
        categories: beauty_categories.reduce((accumulator, category) => {
          return {
            ...accumulator,
            [category.id]: {
              id: category.id,
              name: category.name,
              addons: category.addons,
              types: this.sortArrayIntoObject(category.types, "id"),
            },
          };
        }, {}),
      };
      if (this.isOneTimeOrder) {
        const beautyType = this.filterBeautyTypes(beautyTypes, this.order);
        if (beautyType.length > 0) {
          const { id, service_type, beauty_categories } = beautyType[0];
          this.beautyType = {
            id,
            name: service_type,
            categories: beauty_categories.reduce((accumulator, category) => {
              return {
                ...accumulator,
                [category.id]: {
                  id: category.id,
                  name: category.name,
                  addons: category.addons,
                  types: this.sortArrayIntoObject(category.types, "id"),
                },
              };
            }, {}),
          };
        }
      }
      if (beautyType.length) {
        this.setBeautyItemSpecifics();
      }
    },
    filterBeautyTypes(beautyTypes, order) {
      return beautyTypes.filter((beautyType) => {
        // Return true if any beauty_category in beautyType matches any service in services_list
        return beautyType.beauty_categories.some((beautyCategory) => {
          // Return true if this beauty_category matches any service in services_list
          return order.extra_details.services_list.some((service) => {
            return service.beauty_category.id === beautyCategory.id;
          });
        });
      });
    },
    setBeautyItemSpecifics() {
      if (this.order.extra_details.services.length) {
        this.items = this.order.extra_details.services.map(item => {
          let activities = BEAUTY_ACTIVITIES(item)
          let allActivity = Object.keys(activities)
          let last_activity = "";
          allActivity.forEach((item, index) => {
            if (activities[item].status) {
              if (index == 0) {
                last_activity = activities[item].label
              } else {
                last_activity = activities[allActivity[index - 1]].label
              }
              return
            }
          })
          return {
            category: item.beauty_category.id,
            categoryName: item.beauty_category.name,
            type: item.beauty_category_type.id,
            typeName: item.beauty_category_type.name,
            variation: item.beauty_category_variation,
            addons: item.beauty_item_addons == null ? [] : item.beauty_item_addons,
            sp: item.service_provider.id,
            spName: item.service_provider.name,
            last_marked_activity: last_activity ? last_activity : "Not Assigned",
            activities: activities,
            beauty_service_id: item.id,
            beautician_id: item.beautician ? item.beautician.id : null
          }
        })
      } else {
        this.beautyItems.forEach((item) => {
          const orderItem = this.order[`${item}_item`];

          const beautyCategoryId = this.order[`${item}_category_id`] || null;
          const beautyCategoryTypeId =
            this.order[`${item}_category_type_id`] || null;

          const { name, types } = beautyCategoryId
            ? this.beautyType.categories[beautyCategoryId]
            : {};
          const beautyCategoryName = name || "";
          const beautyCategoryTypeName =
            beautyCategoryId && beautyCategoryTypeId
              ? types[beautyCategoryTypeId].name
              : "";
          const beautyVariationId = this.order[`${item}_item`] || "";
          const beautyItemSp = this.order[`sp_${item}_item`] || null;
          let beautyVariation = { name: "" }
          beautyVariation = beautyVariationId ? this.beautyType.categories[beautyCategoryId].types[beautyCategoryTypeId].variations.filter(item => {
            return item.name.toLowerCase() === beautyVariationId.toLowerCase()
          })[0] : {}
          if (orderItem) {
            this.items.push({
              category: beautyCategoryId,
              categoryName: beautyCategoryName,
              type: beautyCategoryTypeId,
              typeName: beautyCategoryTypeName,
              variation: beautyVariation,
              addons: this.beautyItemAddons
                .map((addon) =>
                  this.order[`${item}_item_addon_${addon}`]?.toLowerCase()
                )
                .filter((addon) => addon),
              sp: beautyItemSp ? beautyItemSp : null,
              spName: beautyItemSp
                ? this.beautyServicePartners[beautyItemSp].name
                : "",
              last_marked_activity: "Not Assigned",
              activities: BEAUTY_ACTIVITIES({}),

            });
          }
        });
      }
    },
    toggleEdit() {
      this.$emit("getOrder");
      this.editing = false;
    },
  },
};
</script>

<style scoped lang="scss">
.label {
  color: var(--eden-grey-secondary);
  font-weight: 500;
  font-size: 0.775rem;
  margin: 12px 0 10px;
}

.addons {
  margin-top: 5px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .addon {
    background: #f0f4f2;
    border-radius: 8px;
    color: var(--eden-grey-secondary);
    font-size: 0.75rem;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 5px 8px;

    span {
      &:last-child {
        margin-left: 24px;
      }
    }

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}
</style>
