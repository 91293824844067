<template>
  <div class="edit-order">
    <eden-overview-card :title="title">
      <template slot="action">
        <div class="edit-order__actions">
          <el-button plain @click="undoChanges">Undo Changes </el-button>
          <el-button type="primary" :loading="updating" @click="saveChanges">
            Save Changes
          </el-button>
        </div>
      </template>
      <template slot="content">
        <div class="edit-order__banner" @click="$emit('toggle-edit')">
          <span class="font-sm">
            You are now editing this order.
            <span class="text-primary">
              Click here to exit without saving changes.
            </span>
          </span>
        </div>
        <el-form :model="form" label-position="top" ref="form">
          <div v-for="(item, index) in form.items" :key="index" class="edit-order__item fadeIn">
            <el-row type="flex" :gutter="10">
              <el-col :md="8">
                <el-form-item label="Category" :prop="'items.' + index + '.category'" :rules="validateField('Category')">
                  
                  <el-select v-model.number="item.category" @change="setCategoryTypes(index)">
                    <el-option v-for="category in beautyType.categories" :key="category.id" :label="category.name"
                      :value="category.id" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="8">
                <el-form-item label="Type" :prop="'items.' + index + '.type'" :rules="validateField('Type')">
                  <el-select v-model.number="item.type" @change="setCategoryTypeVariations(index)">
                    <el-option v-for="type in setTypes(item.category)" :key="type.id"
                      :label="formatToTitleCase(type.name)" :value="type.id" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :md="8">
                <el-form-item label="Variation" :prop="'items.' + index + '.variation'"
                  :rules="validateField('Variation')">
                  <el-select v-model="item.variation" value-key="id">
                    <el-option v-for="variation in setVariations(
                      item.category,
                      item.type,
                    )" :key="variation.id" :label="formatToTitleCase(variation.name)" :value="variation" />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item label="Addons">
                  <el-select v-model="item.addons" multiple>
                    <el-option v-for="(addon, index) in setAddons(item.category)" :key="index" :label="addon.name"
                      :value="addon.name.toLowerCase()" />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" :gutter="10">
              <el-col :span="12">
                <el-form-item label="Service Partner" :prop="'items.' + index + '.sp'" :rules="{
                  required: true,
                  message: 'Service Partner is required',
                  trigger: 'change',
                }">
                  <el-select v-model.number="item.sp" placeholder="Select Service Partner" filterable>
                    <el-option v-for="(sp, index) in beautyServicePartners" :key="index" :label="sp.name"
                      :value="sp.id" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="Assigned Beautician" :prop="'items.' + index + '.beautician_id'">
                  <el-select v-model="item.beautician_id" filterable value-key="id" placeholder="Search beauticians"
                    :disabled="!allowAccessFor(['admin', 'operations'])" @change="checkBeauticianCount">
                    <el-option v-for="(beautician, index) in beauticians" :key="index"
                      :label="`${beautician.firstname} ${beautician.lastname}`" :value="beautician.id">{{
                        formatFullName(beautician) }}</el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="24" class="is-flex justify-center">
                <el-button :type="'text'" :class="'danger'" :icon="'eden-icon-delete'" @click="deleteItem(index)">
                  Delete this item
                </el-button>
              </el-col>
            </el-row>
          </div>
          <el-row type="flex">
            <el-col :span="24" class="is-flex justify-center">
              <el-button :type="'text'" :icon="'eden-icon-add-circle'" @click="addItem">Add another item
              </el-button>
            </el-col>
          </el-row>
        </el-form>
      </template>
    </eden-overview-card>
  </div>
</template>

<script>
import beauty from "@/requests/orders/beauty";
import beauticians from "@/requests/logistics/directory/beauticians";
import { BEAUTY_ITEM_ADDONS, BEAUTY_ITEMS } from "./beauty.config";
import schedule from "@/requests/logistics/schedule";

export default {
  name: "OrderBeautyEdit",
  props: {
    orderId: {
      type: Number,
      default: null,
    },
    service: {
      type: String,
      default: null,
    },
    orderDeliveryDate: {
      type: String,
      default: "",
    },
    orderType: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    beautyType: {
      type: Object,
      default() {
        return {};
      },
    },
    customerId: {
      type: Number,
      default: null,
    },
    disableUpdate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      beautyItems: BEAUTY_ITEMS(),
      beautyItemAddons: BEAUTY_ITEM_ADDONS(),
      form: {
        items: [],
      },
      updating: false,
      beauticians: [],
      deleteItems: [],
    };
  },
  computed: {
    title() {
      return `Edit Order (${this.form.items.length})`;
    },
    beautyServicePartners() {
      return this.$store.getters.service_partners_list.beauty;
    },
  },
  created() {
    this.setFormData();
    this.setBeauticians();
  },
  methods: {
    setBeauticians() {
      beauticians
        .list()
        .then((response) => {
          if (response.data.status) {
            this.beauticians = response.data.data;
          }
        })
        .catch();
    },
    checkBeauticianCount(beautician_id) {
      let beautician = this.beauticians.filter(
        (item) => item.id === beautician_id,
      );
            let payload = {
        user_id: beautician_id,
        user_type: "beautician",
        service_date: this.orderDeliveryDate.slice(0, 10),
      };
            schedule
        .orderScheduleCount(payload)
        .then((response) => {
          const count = response.data.data.order_schedule_count;
                    if (count) {
                        this.$message.warning(
              `Beautician ${beautician[0].firstname} ${beautician[0].lastname
              } has already been assigned ${count} orders for ${this.formatDate(
                this.orderDeliveryDate,
                "dddd",
              )}`,
            );
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setFormData() {
            this.form.items = this.items
            // if (this.orderType) {
      //   this.form.items = this.form.items.map(item => {
      //     item.category = item.categoryName;
      //     item.type = item.typeName;
      //     item.variation = item.variation.name;
      //     item.addons = item.categoryName;
      //     return item;
      //   });
      // }
    },
    addItem() {
            const items = this.form.items;
            if (items.length < 4) {
        
        this.form.items.push({
          category: null,
          type: null,
          variation: "",
          addons: [],
          sp: null,
        });
      } else {
        this.$message.error("Maximum amount of services added");
      }
      
    },
    deleteItem(index) {
      this.deleteItems.push(this.form.items[index].beauty_service_id);
      this.form.items.splice(index, 1);
    },
    setCategoryTypes(index) {
      
      const item = this.form.items[index];
      
      item.type = "";
      item.variation = "";
      item.addons = [];
      
    },
    setCategoryTypeVariations(index) {
      
      this.form.items[index].variation = "";
      
    },
    setTypes(id) {
      
      const category = id ? this.beautyType.categories[id] : [];
      
      return category?.types || [];
    },
    setVariations(categoryId, typeId) {
      
      const type =
        categoryId && typeId
          ? this.beautyType.categories[categoryId]?.types[typeId]
          : [];
      return type?.variations || [];
    },
    setAddons(id) {
      
      return id ? this.beautyType?.categories[id]?.addons : [];
    },
    formattedBeautyItems() {
      
      let payload = {
        beauty_category_id: [],
        beauty_category_type_id: [],
        beauty_category_variation_id: [],
        beauty_item_addons: [],
        service_provider_id: [],
        beautician_id: [],
        beauty_service_id: [],
        sp_specifics: [],
      };
      
      this.form.items.forEach((item) => {
        
        payload.beauty_category_id.push(item.category);
        payload.beauty_category_type_id.push(item.type);
        payload.beauty_category_variation_id.push(item.variation.id);
        payload.beauty_item_addons.push(item?.addons);
        payload.service_provider_id.push(item.sp);
        if (item.beauty_service_id) {
          payload.beauty_service_id.push(item.beauty_service_id);
        }
        if (item.beautician_id) {
          payload.beautician_id.push(item.beautician_id);
        }
        payload.sp_specifics.push({
          serviceProviderID: item.sp,
          specifics: item.variation.name,
          beauty_addons_for_specifics: item?.addons.join(),
        });
      });
      return payload;
    },
    undoChanges() {
      this.setFormData();
    },
    confirmTimeAndUpdate() {
      if (!this.disableUpdate) {
        this.$message.error("Order update window has passed.");
      } else {
        this.saveChanges();
      }
    },
    delete() {
      const payload = {
        beauty_services_id: this.deleteItems,
      };
      beauty
        .deleteItem(payload)
        .then(() => {
          this.deleteItems = [];
          this.$emit("toggle-edit");
          this.$emit("get-order");
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.updating = false;
        });
    },

    saveChanges() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.updating = true;
        const payload = this.formattedBeautyItems();
        const spPayload = {
          sp_specifics: JSON.stringify(payload.sp_specifics),
        };
        delete payload.sp_specifics;
        // pPayload);
        beauty
          .updateService(this.orderId, payload, spPayload)
          .then((response) => {
            if (response[0].data.status) {
              this.$message.success("Order details updated successfully");
            }
            this.updating = false;
            if (this.deleteItems.length) {
              this.delete();
              return;
            }
            this.$emit("toggle-edit");
            this.$emit("get-order");
          })
          .catch((error) => {
            this.updating = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });

        return true;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
