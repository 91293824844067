import axios from "axios";

export default {
  updateService(id,payload,spPayload){
    return axios.all([
      axios.post(`crm/order/beauty/update_preference/${id}`,payload),
      axios.post(`orders/${id}/service_provider`, spPayload),
    ]);

  },
  deleteItem(payload){
    return axios.delete(`crm/order/beauty/delete_service`,{data:payload})
  },
  markActivity(payload) {
    return axios.post("crm/operations/beauty", payload);
  },
};
